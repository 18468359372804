<template>
    <v-list dense nav dark>
        <m-nav-item-home />

        <m-nav-item-users />

        <m-nav-item-keytubes />

        <m-nav-item-gear />

        <v-divider />
    </v-list>
</template>

<script>
import mNavItemHome from './itemHome.vue';
import mNavItemUsers from './itemUsers.vue';
import mNavItemKeytubes from './itemKeytubes.vue';
import mNavItemGear from './itemGear.vue';

export default {
    name: 'mNavMenu',
    components: {
        mNavItemHome,
        mNavItemUsers,
        mNavItemKeytubes,
        mNavItemGear,
    }
}
</script>