<template>
    <v-container style="max-width: 25em;">
        <v-alert
            type="error"
            v-show="!!error.code"
            icon="mdi-alert-outline"
            outlined text
        >
            {{ errorText }}
        </v-alert>
        <v-card elevation="10">
            <v-card-title class="justify-center">
                <img 
                    :src="$vuetify.theme.dark ? '/img/logo-b.svg' : '/img/logo-a.svg'" 
                    width="50%" 
                    class="mt-4"
                />
            </v-card-title>
            <v-form
                ref="form"
                @submit="validate" @submit.prevent
                :disabled="!getAuthReady"
                lazy-validation
                v-model="valid"
            >
                <v-card-text class="pb-0">
                    <v-text-field
                        label="Adresse email"
                        v-model="email"
                        type="email"
                        :rules="[rules.required, rules.email]"
                        required
                    />
                    <v-text-field
                        label="Mot de passe"
                        v-model="password"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show ? 'text' : 'password'"
                        @click:append="show = !show"
                        :rules="[rules.required]"
                        required
                    />
                    
                </v-card-text>
                <v-card-actions>
                    <v-checkbox
                        label="Se souvenir de moi"
                        v-model="remember"
                    />
                    <v-spacer/>
                    <v-btn
                        type="submit"
                        :disabled="!valid || loading"
                        :loading="loading"
                        color="accent"
                        
                    >
                        Connexion
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-container>
</template>

<script>
import fbApp from '@/firebase';
import { browserLocalPersistence, browserSessionPersistence, getAuth, setPersistence, signInWithEmailAndPassword } from "firebase/auth";
const auth = getAuth(fbApp);

export default {
    name: 'mLogin',
    data: () => ({
        email: '',
        password: '',
        remember: false,
        loading: false,
        show: false,
        valid: true,
        error: {},
        rules: {
            required: value => !!value || 'Ce champ est requis',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Format invalide'
            }
        },
        errorTexts: {
            'auth/wrong-password': 'Mot de passe erroné, veuillez réessayer',
            'auth/user-not-found': 'Pas d\'utilisateur trouvé avec cette adresse mail.',
            'auth/user-disabled': 'Le compte a été désactivé.',
            'auth/invalid-email': 'Format de l\'adresse mail invalide.',
            'auth/too-many-requests': "Trop de tentatives de connexion.",
        }
    }),
    computed: {
        errorText() {
            if(this.error.code) {
                let msg = this.errorTexts[this.error.code];
                if (msg)
                    return msg;
                else {
                    console.table(this.error);
                    return 'Erreur inconnue, veuillez contacter le responsable informatique';
                }
            }
            else
                return '';
        },
        getAuthReady() {
            return this.$store.state.authReady;
        }
    },
    methods: {
        validate() {
            if(this.$refs.form.validate()) {
                this.loading = true;
                const AuthPersistence = this.remember ? browserLocalPersistence : browserSessionPersistence;
                
                setPersistence(auth, AuthPersistence).then(() => {
                    return signInWithEmailAndPassword(auth, this.email, this.password);
                }).then(() => {
                    this.email = '';
                    this.password = '';
                }).catch(err => {
                    this.error = err;
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
    },
}
</script>

<style>

</style>