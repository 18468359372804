import Vue from 'vue'
import VueRouter from 'vue-router'
import mHome from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
    { path: '/', redirect: {name: 'Home'} },
    {
        path: '/a-propos',
        name: 'About',
        component: () => import('@/views/About.vue'),
    },
    {
        path: '/console',
        name: 'Home',
        component: mHome,
    },
    { path: '/cylindres', redirect: {name: 'Keytubes list'} },
    {
        path: '/cylindres/liste',
        name: 'Keytubes list',
        component: () => import(/* webpackChunkName: "keytubes" */ '@/views/KeytubesList.vue'),
    },
    {
        path: '/cylindres/carte',
        name: 'Keytubes map',
        component: () => import(/* webpackChunkName: "keytubes" */ '@/views/KeytubesMap.vue'),
    },
    {
        path: '/cylindres/c/:id',
        name: 'Keytube details',
        component: () => import(/* webpackChunkName: "keytubes" */ '@/views/KeytubeDetails.vue'),
    },
    {
        path: '/equipement/liste',
        name: 'Gear',
        component: () => import(/* webpackChunkName: "gear" */ '@/views/GearList.vue'),
    },
    {
        path: '/equipement/p/:id',
        name: 'Gear details',
        component: () => import(/* webpackChunkName: "gear" */ '@/views/GearDetails.vue'),
    },
    {
        path: '/equipement/stock',
        name: 'Gear stock',
        component: () => import(/* webpackChunkName: "gear" */ '@/views/GearStock.vue'),
    },
    {
        path: '/membres/liste',
        name: 'Users',
        component: () => import('@/views/Users.vue'),
    },
    {
        path: '/membres/p/:id',
        name: 'User profile',
        component: () => import('@/views/UserProfile.vue'),
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
