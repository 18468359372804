<template>
    <!-- <v-switch
        v-model="$vuetify.theme.dark"
        color="white"
        prepend-icon="mdi-brightness-7"
        append-icon="mdi-brightness-5" 
        class="my-auto"
        hide-details
    >
    </v-switch> -->
    <v-checkbox
        v-model="$vuetify.theme.dark"
        off-icon="mdi-white-balance-sunny"
        on-icon="mdi-weather-night"
        color="white"
        hide-details
    >
    </v-checkbox>
</template>

<script>
export default {
    name: 'mAppToggleDarkMode',
}
</script>