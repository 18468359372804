<template>
    <v-snackbar
    v-model="getError.active"
    color="error"
    >
    <strong>Erreur ({{ getError.code || 'null' }}) : </strong> {{ getError.message }}

        <template v-slot:action="{ attrs }">
            <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="getError.active = false">
            Fermer
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: 'm-snackbar-error',
    computed: {
        getError() {
            let error = this.$store.state.error;
            switch (error.type) {
                case 'firebase-firestore':
                    error.message = firebaseFirestoreErrors[error.code];
                    break;
                case 'firebase-auth':
                    error.message = firebaseAuthErrors[error.code];
                    break;
                case 'firebase-storage':
                    error.message = firebaseStorageErrors[error.code];
                    break;
                default:
                    break;
            }
                
            return error;
        },
    },
};

const firebaseFirestoreErrors = {
    "cancelled": "L'opération a été annulée",
    "unknown": "Erreur inconnue ou d'un domaine différent",
    "invalid-argument": "Paramètre de requête invalide",
    "deadline-exceeded": "Délai de l'opération dépasée",
    "not-found": "Le document demandé est introuvable",
    "already-exists": "Ce document existe déjà",
    "permission-denied": "Vous n'avez pas les droits d'accès nécessaires",
    "resource-exhausted": "Les resources disponibles sont épuisées",
    "failed-precondition": "Le système n'est pas dans l'état adéquat pour cette opération",
    "aborted": "L'opération a échouée",
    "out-of-range": "L'opération à dépassé les champs autorisés",
    "unimplemented": "L'opération n'est pas disponible ou n'est pas activée",
    "internal": "Erreur interne, critique. Contactez de suite le responsable informatique",
    "unavailable": "Service temporairement indisponible",
    "data-loss": "Données perdues ou corrumpues définitivement",
    "unauthenticated": "Vous devez être connecté pour effectuer cette opération"
};

const firebaseAuthErrors = {
    "auth/app-deleted": "Cette application a été supprimée",
    "auth/app-not-authorized": "Ce domaine n'est pas autorisé à utiliser cette application",
    "auth/argument-error": "Une méthode a été appelée avec des arguments incorrects",
    "auth/invalid-api-key": "La clé API est invalide",
    "auth/invalid-user-token": "Votre connexion est échue, veuillez vous reconnecter",
    "auth/invalid-tenant-id": "Tenant ID is invalid",
    "auth/network-request-failed": "Problème de réseau",
    "auth/operation-not-allowed": "Méthode de connexion non autorisée",
    "auth/requires-recent-login": "Connexion trop peu récente",
    "auth/too-many-requests": "Trop de tentatives ; veuillez réessayer plus tard",
    "auth/unauthorized-domain": "Ce domaine n'a pas été autorisé pour les opérations OAuth",
    "auth/user-disabled": "Votre compte a été désactivé ; veuillez contacter l'administrateur",
    "auth/user-token-expired": "Votre connexion est échue ou le compte supprimé, veuillez vous reconnecter",
    "auth/web-storage-unsupported": "Le navigateur n'admet pas le stockage de données"
};

const firebaseStorageErrors = {
    "storage/unknown": "Une erreur inconnue est survenue",
    "storage/object-not-found": "Aucun objet trouvé à la référence fournie",
    "storage/bucket-not-found": "Aucun bucket n'est configuré",
    "storage/project-not-found": "Aucun projet n'est configuré",
    "storage/quota-exceeded": "La limite de stockage est atteinte, contactez le responsable informatique",
    "storage/unauthenticated": "Vous devez être connecté",
    "storage/unauthorized": "Vous n'avez pas les droits nécessaires",
    "storage/retry-limit-exceeded": "La limite de tentatives à l'opération est dépassée",
    "storage/invalid-checksum": "Code de verification du fichier incorrecte",
    "storage/canceled": "Opération annulée",
    "storage/invalid-event-name": "Nom d'événement inconnue",
    "storage/invalid-url": "Lien de requête invalide",
    "storage/invalid-argument": "Argument d'envoi invalide",
    "storage/no-default-bucket": "Aucun bucket par défaut n'a été paramétré",
    "storage/cannot-slice-blob": "Tentez de téléverser le fichier de nouveau",
    "storage/server-file-wrong-size": "Echec lors de l'envoi du fichier, réessayez",
}

</script>