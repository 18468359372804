<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6" md="4" xl="3">
                <v-card class="mb-5">
                    <v-card-title>
                        Bienvenue
                    </v-card-title>

                    <v-card-subtitle>
                        {{ sayHello }} 
                        {{ getName }}
                    </v-card-subtitle>

                    <v-card-text v-if="true">
                        Bienvenue sur focus. Cette appliication est en cours de développement et peut encore contenir des bugs.
                        <br>
                        En cas de problème, merci d'informer Martijn Sassen :
                        <ul>
                            <li><a href="https://wa.me/41791599900" target="_Blank" rel="noopener noreferrer">WhatsApp (079 159 99 00)</a></li>
                            <li><a href="tel:+41791599900">Téléphone (079 159 99 00)</a></li>
                            <li><a href="mailto:it-services@sdiscoeurdelavaux.ch">it-services@sdiscoeurdelavaux.ch</a></li>
                            <li><a href="mailto:it@msassen.ch">it@msassen.ch</a></li>
                        </ul>
                    </v-card-text>
                </v-card>

                <v-card>
                    <v-card-title>
                        Une idée ?
                    </v-card-title>
                    <v-card-text>
                        Vous avez une idée pour cette application ? C'est avec plaisir qu'on en discute.
                        <br>
                        Voici les projets en cours :
                        <ul>
                            <li>Module équipement</li>
                            <li>Module cylindres feu</li>
                            <li>Module d'administration mySTOP</li>
                            <li>Checklists matériel</li>
                            <li>Votre idée ?</li>
                        </ul>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4" xl="3">
                <v-card class="mb-5">
                    <v-card-title>
                        Version 22.2.0
                    </v-card-title>

                    <v-card-subtitle>
                        Sortie le 25 mars 2022
                    </v-card-subtitle>

                    <v-card-text>
                        <strong>Nouveautés depuis 2.1</strong>
                        <ul>
                            <li>Bouton "Changer mot de passe" <m-new-chip/></li>
                            <li>Import d'utilisateurs <m-new-chip/></li>
                            <li>Optimisations du code</li>
                            <li>Changements esthétiques</li>
                            <li>
                                Module Equipement :
                                <ul>
                                    <li>Barre de recherche <m-new-chip/></li>
                                    <li>Filtre par détachement <m-new-chip/></li>
                                    <li>Vue en détail par item <m-new-chip/></li>
                                    <li>Nouveaux types/variantes/modèles</li>
                                </ul>
                            </li>
                            <li>Réparé : Affichage d'erreurs sur la page de connexion</li>
                        </ul>
                        <br>
                        <strong>Problèmes connus :</strong>
                        <ul>
                            <li>Equipement : Affichage de l'état de chargement</li>
                        </ul>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import mNewChip from '../components/home/new.vue';

export default {
    name: 'mHome',
    components: {
        mNewChip
    },
    computed: {
        sayHello() {
            const now = new Date();
            return now.getHours() > 18 ? 'Bonsoir' : 'Bonjour';
        },
        getName() {
            const name = this.$store.state.user.data.name;
            return `${name.first} ${name.last}`;
        }
    },
    methods: {
    }
}
</script>

<style>

</style>