/**
 * @file Creates the class UserProfile.
 * @author Martijn Sassen
 */

/** 
 * Class representing a user's profile.
 * @author Martijn Sassen
 * @version 1.4.1
 */
export default class UserProfile {
    uid = '';
    address = { street: [] };
    claims = {};
    contact = { email: [], phone: {} }
    exGroup = {}
    hasAccount;
    functions = {}
    imageRights = {}
    joinDate;
    name = { first: undefined, last: undefined };
    nip;
    rank;
    roles = {};
    section;
    station;
    status;
    valid = false;

    /**
     * Create a profile.
     * @param {object} userData User's data from database
     * @since 1.0.0
     */
    constructor(userData) {
        if (typeof(userData) == 'object') {
            this.uid = userData.uid || null;
            this.address = {
                street: userData?.address?.street ?? [],
                town: userData?.address?.town ?? '',
                zip: userData?.address?.zip ?? '',
            };
            this.claims = userData.claims || {};
            this.contact = {
                email: userData.contact?.email ?? [],
                phone: userData.contact?.phone ?? {}
            };
            this.exGroup = userData.exGroup || {};
            this.hasAccount = Boolean(userData.hasAccount);
            this.functions = userData.functions || {};
            this.imageRights = userData.imageRights || {};
            this.joinDate = userData.joinDate || '';
            this.name = userData.name || {};
            this.nip = userData.nip || null;
            this.rank = userData.rank || null;
            this.roles = userData.roles || {};
            this.section = userData.section || null;
            this.station = userData.station || null;
            this.status = userData.status || null;
            this.valid = userData.valid || false;
        }
    }

    /** 
     * Update the user's data
     * @since 1.2.0
     */
    updateData(data) {
        if (typeof(data) === 'object')
            Object.assign(this, data);
        else
            console.error("UserProfile.updateData(data): argument 'data' must be an object");
    }

    /** 
     * Get the initials of the person (first letter of first and last name).
     * @returns {string} The initials
     * @since 1.1.0
     */
    getInitials() {
        return this.name ? this.name.first.charAt(0) + this.name.last.charAt(0) : undefined;
    }

    /** 
     * Get the short name (last name followed by first letter of first name).
     * @returns {string} Short name
     * @since 1.2.0
     */
    getShortName() {
        return this.name ? this.name.last + this.name.first.charAt(0) : undefined;
    }

    /**
     * Get profile information.
     * Includes contact, exGroup, functions, name, nip, rank, roles, section
     * @returns {object} Profile information
     * @since 1.1.1
     */
    getProfileInfo() {
        return {
            address: this.address,
            contact: this.contact,
            exGroup: this.exGroup,
            functions: this.functions,
            hasAccount: this.hasAccount,
            imageRights: this.imageRights,
            joinDate: this.joinDate,
            name: this.name,
            nip: this.nip,
            rank: this.rank,
            roles: this.roles,
            section: this.section,
            station: this.station,
            status: this.status,
            valid: this.valid,
        }
    }

    /**
     * Returns true if user has any claim passed as param
     * @param  {...string} claims The claims to test for
     * @returns {boolean} User has any of the claims
     * @since 1.4.0
     */
    hasAnyClaim(...claims) {
        if (!this.claims)
            return false;

        for (let claim of claims) {
            if (this.claims[claim] === true)
                return true;
        }
        return false;
    }

    /**
     * Return true if user has all claims passed as param
     * @param  {...any} claims The claims to test for
     * @returns {boolean} User has all the claims
     * @since 1.4.0
     */
    hasAllClaims(...claims) {
        if (!this.claims)
            return false;
        
        let count = 0;
        for (let claim of claims) {
            if (this.claims[claim] === true)
                count++;
        }
        return count == claims.length;
    }

    /**
     * Return true if user has any claim passed as param or is superAdmin or superMod.
     * @param  {...string} claims The claims to test for
     * @returns {boolean} User has any of the claims or is superAdmin|superMod
     * @since 1.4.0
     */
    hasClaimOrSuper(...claims) {
        if (this.hasAnyClaim('superAdmin', 'superMod', ...claims))
            return true;
        return false;
    }
}