<template>
    <!-- For admins -->
    <v-list-group
        v-if="canSee"
        prepend-icon="mdi-tshirt-crew"
        color="dark"
        no-action
    >
        <template #activator>
          <v-list-item-title>Equipement</v-list-item-title>
        </template>

        <v-list-item :to="{ name: 'Gear' }">
            <v-list-item-content>
                <v-list-item-title>Membres</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'Gear stock' }">
            <v-list-item-content>
                <v-list-item-title>Stock</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list-group>

    <!-- For everybody -->
    <!-- <v-list-item v-if="canSee" :to="{ name: 'Gear' }">
        <v-list-item-icon>
            <v-icon>mdi-tshirt-crew</v-icon>
        </v-list-item-icon>
        
        <v-list-item-content>
            <v-list-item-title>Equipement</v-list-item-title>
        </v-list-item-content>
    </v-list-item> -->
</template>

<script>
export default {
    name: 'mNavItemGear',
    computed: {
        canSee() {
           return this.$store.getters.canAccess('gear_user', 'gear_mod', 'gear_admin');
        }
    },
}
</script>