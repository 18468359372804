<template>
    <v-snackbar
        v-model="getData.active"
        :color="getData.color"
    >
        <v-icon v-if="Boolean(getData.icon)" left>{{ getData.icon }}</v-icon>
        {{ getData.text }}

        <template v-slot:action="{ attrs }">
            <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="getData.active = false">
            Fermer
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: 'mAppSnackbar',
    computed: {
        getData() {
            return this.$store.state.snackbar;
        },
    },
};

</script>