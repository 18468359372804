import { getApp, getApps, initializeApp } from 'firebase/app';

// Init firebase
let firebaseConfig = {
    apiKey: "AIzaSyCUxBsIY1LBZ4LKI_vD4CVaG2gh9skQ9mo",
    authDomain: "cdl-focus.firebaseapp.com",
    databaseURL: "https://cdl-focus.firebaseio.com",
    projectId: "cdl-focus",
    storageBucket: "cdl-focus.appspot.com",
    messagingSenderId: "177614997331",
    appId: "1:177614997331:web:6acd6dec31ab92fd5283a6"
};
firebaseConfig.apiKey = process.env.VUE_APP_FIREBASEKEY;

export default getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();