<template>
    <div class="text-center">
        <v-menu offset-y>

            <template v-slot:activator="{ on, attrs }">
                <v-avatar
                v-bind="attrs" v-on="on"
                color="white"
                size="46">
                    <m-profile-avatar-icon />
                </v-avatar>
            </template>

            <v-list>
                <v-list-item :to="{name: 'User profile', params: {id: getUid}}">
                    <v-list-item-icon>
                        <v-icon>mdi-account-edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        Profil
                    </v-list-item-title>
                </v-list-item>

                <m-profile-avatar-change-password />

                <m-profile-avatar-logout />
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import mProfileAvatarLogout from './logout.vue';
import mProfileAvatarChangePassword from './changePassword.vue';
import mProfileAvatarIcon from './icon.vue'

export default {
    name: 'mUserProfileAvatar',
    components: {
        mProfileAvatarLogout,
        mProfileAvatarChangePassword,
        mProfileAvatarIcon,
    },
    data: () => ({
    }),
    computed: {
        getUid() {
            return this.$store.state.user.data.uid;
        }
    }
}
</script>