<template>
    <v-list-item @click="logout">
        <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
            Déconnexion
        </v-list-item-title>
    </v-list-item>
</template>

<script>
import fbApp from '@/firebase';
import { getAuth, signOut } from 'firebase/auth';

export default {
    name: 'mProfileAvatarLogout',
    methods: {
        logout() {
            signOut(getAuth(fbApp));
        },
    },
}
</script>