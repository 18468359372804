<template>
    <v-list-group v-if="canSee"
    color="dark"
    no-action
    prepend-icon="mdi-alpha-f-circle"
    >
        <!-- <template #prependIcon>
            <v-list-item-avatar tile max-height="100%">
                <v-img src="/img/keytubes-trans-s.png" contain  max-height="100%" />
            </v-list-item-avatar>
        </template> -->

        <template #activator>
            <v-list-item-title>Cylindres feu</v-list-item-title>
        </template>

        <v-list-item :to="{ name: 'Keytubes map' }">
            <v-list-item-content>
                <v-list-item-title>Carte</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'Keytubes list' }">
            <v-list-item-content>
                <v-list-item-title>Liste</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list-group>
</template>

<script>
export default {
    name: 'mNavItemKeytubes',
    computed: {
        canSee() {
           return this.$store.getters.canAccess('keytubes_user', 'keytubes_mod', 'keytubes_admin');
        }
    },
}
</script>