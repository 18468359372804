<template>
    <v-dialog
        v-model="dialog"
        max-width="400"
    >
        <template #activator="{on, attrs}">
            <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-icon>
                    <v-icon>mdi-form-textbox-password</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    Changer mot de passe
                </v-list-item-title>
            </v-list-item>
        </template>

        <v-card>
            <v-form 
                ref="myForm"
                @submit.prevent="submit()"
            >
                <v-card-title>
                    Changer de mot de passe
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        v-for="(field, key) in fields"
                        :key="key"
                        v-model="field.pw"
                        :label="field.label"
                        :append-icon="field.show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="field.show ? 'text' : 'password'"
                        @click:append="field.show = !field.show"
                        :rules="field.rules"
                    />
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        :disabled="sendLoading"
                        color="secondary"
                        @click="dialog = false"
                    >
                        Annuler
                    </v-btn>
                    <v-btn
                        type="submit"
                        :disabled="sendLoading"
                        :loading="sendLoading"
                        color="primary"
                    >
                        Confirmer
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>

    </v-dialog>
</template>

<script>
import fbApp from '@/firebase';
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from "firebase/auth";
const auth = getAuth(fbApp);

import { REQUIRED } from '@/constants/rules';

export default {
    name: 'mProfileAvatarChangePassword',
    data() { return {
        dialog: false,
        fields: {
            old: {
                pw: '',
                show: false,
                label: 'Mot de passe actuel',
                rules: [REQUIRED],
            },
            new1: {
                pw: '',
                show: false,
                label: 'Nouveau mot de passe',
                rules: [REQUIRED, val => val.length >= 8 || 'Le mot de passe doit avoir au moins 8 caractères'],
            },
            new2: {
                pw: '',
                show: false,
                label: 'Répétez le mot de passe',
                rules: [REQUIRED, val => val == this.fields.new1.pw || 'Les mots de passe sont différents' ],
            },
        },
        sendLoading: false,
    }},
    methods: {
        submit() {
            if (this.$refs.myForm.validate()) {
                if (this.fields.new1.pw != this.fields.new2.pw)
                    return;

                this.sendLoading = true;
                
                const user = auth.currentUser;
                const userCredential = EmailAuthProvider.credential(user.email, this.fields.old.pw);
                reauthenticateWithCredential(user, userCredential).then(() => {
                    return updatePassword(user, this.fields.new1.pw);
                }).then(() => {
                    this.$store.commit('setSnackbar', { text: 'Mot de passe changé', color: 'success' });
                    this.dialog = false;
                }).catch(error => {
                    this.$store.commit('setError', { type: 'firebase-auth', code: error.code, message: error.message });
                }).finally(() => {
                    this.sendLoading = false;
                });
            }
        },
    },
}
</script>