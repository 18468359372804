<template>
    <!-- For admins -->
    <!-- <v-list-group
        v-if="canSeeAll"
        prepend-icon="mdi-account-multiple"
        color="dark"
        no-action
    >
        <template #activator>
          <v-list-item-title>Membres</v-list-item-title>
        </template>

        <v-list-item :to="{ name: 'Users' }">
            <v-list-item-content>
                <v-list-item-title>Liste</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'Users admin' }">
            <v-list-item-content>
                <v-list-item-title>Administration</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list-group> -->

    <!-- For everybody -->
    <v-list-item :to="{ name: 'Users' }">
        <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
        </v-list-item-icon>
        
        <v-list-item-content>
            <v-list-item-title>Membres</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: 'mNavItemUsers',
    computed: {
        canSeeAll() {
           return this.$store.getters.canAccess('users_admin');
        }
    },
}
</script>