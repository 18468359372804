<template>
    <v-app>
        <m-login v-if="!userLoggedIn"/>
        <!-- <m-login v-if="true"/> -->

        <div v-else>
            <v-main>
                <m-skeleton />

                <div class="mt-12 text-center" v-if="!userReady">
                    <v-progress-circular :size="100" :width="8" color="accent" indeterminate />
                    <span class="d-block mt-5">Chargement de votre profil...</span>
                </div>

                <v-alert v-else-if="!userValid"
                class="mx-8 mt-8"
                type="warning"
                icon="mdi-alert-circle"
                prominent>
                    Votre compte n'est pas ou plus valide.
                    <br>
                    Contactez votre QM si vous pensez qu'il s'agisse d'une erreur.
                </v-alert>

                <router-view v-else />
            </v-main>
        </div>
    </v-app>
</template>

<script>
import fb from '@/firebase';
fb;

import mLogin from './views/Login.vue';
import mSkeleton from './components/app/skeleton.vue';

import UserProfile from './assets/script/UserProfile.js';
window.UserProfile = UserProfile;


export default {
    components: { mLogin, mSkeleton },
    name: 'App',
    data: () => ({
    }),
    computed: {
        userLoggedIn() {
            return this.$store.state.user.loggedIn;
        },
        userReady() {
            return this.$store.state.user.ready;
        },
        userValid() {
            return this.$store.state.user.data.valid;
        },
    },
    created() {
        // SOME CODE TO MAJOR EDIT USERS
        // firebase.firestore().collection('users').get().then(docs => {
        //     docs.forEach(doc => {
        //         firebase.firestore().collection('users').doc(doc.id).collection('modules').doc('profile').update({
        //         });
        //         firebase.firestore().collection('users').doc(doc.id).update({
        //         })
        //     });
        // });
    },
    mounted() {
        this.$vuetify.theme.dark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
            this.$vuetify.theme.dark = event.matches;
        });
    },
};

// Force https
if (process.env.NODE_ENV === 'production') {
    if (location.protocol !== 'https:') {
        location.replace('https:' + location.href.substring(location.protocol.length));
    }
}
</script>

<style>
.wordbreak-normal {
    word-break: normal;
}
</style>