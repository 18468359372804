<template>
    <div>
        <v-navigation-drawer
        app
        v-model="drawer"
        dark
        height="100%">
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="title">
                        focus
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        SDIS C&oelig;ur de Lavaux
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar tile>
                    <v-img src="/img/logo-b.svg" eager/>
                </v-list-item-avatar>
            </v-list-item>

            <v-divider/>

            <m-nav-menu/>
            
            <template #append>
                <div class="pb-3 pt-2 text-center">
                    <v-chip class="mx-auto my-1" label :to="{ name: 'About' }">
                        v{{ getAppVer }} | MSassen &copy; {{ new Date().getFullYear() }}
                        <!-- <a href="https://msassen.ch/fr" target="_blank" rel="noopener noreferrer"
                            class="white--text text-decoration-none font-weight-bold"
                        >
                            MSassen
                        </a> -->
                        <!-- <v-icon small color="red">mdi-heart</v-icon> -->
                    </v-chip>
                </div>
            </template>

        </v-navigation-drawer>

        <v-app-bar app
            color="accent"
            dark
        >
            <v-row align="center">
                <v-col class="pl-0">
                    <v-app-bar-nav-icon @click="drawer = !drawer"/>
                </v-col>
                <v-col cols="auto">
                    <m-app-toggle-dark-mode />
                </v-col>
                <v-col cols="auto" class="pl-0">
                    <m-user-profile-avatar />
                </v-col>
            </v-row>
        </v-app-bar>

        <m-snackbar-error />
        <m-app-snackbar />
    </div>
</template>

<script>
import mUserProfileAvatar from '../userProfileAvatar/avatar.vue';
import mNavMenu from '@/components/nav/navMenu.vue';
import mSnackbarError from './snackbarError.vue';
import mAppSnackbar from './appSnackbar.vue';
import mAppToggleDarkMode from './toggleDarkMode.vue';

export default {
    name: 'mSkeleton',
    components: { mUserProfileAvatar, mNavMenu, mSnackbarError, mAppSnackbar, mAppToggleDarkMode },
    data: () => ({
        drawer: null
    }),
    computed: {
        getAppVer() {
            return process.env.VUE_APP_VERSION;
        }
    }
}
</script>

<style>

</style>