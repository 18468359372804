<template>
    <span v-if="userReady" class="text-h6 accent--text">
        {{ getInitials }}
    </span>
    <v-icon v-else color="accent">
        mdi-account
    </v-icon>
</template>

<script>
export default {
    name: 'profileAvatarIcon',
    computed: {
        userReady() {
            return this.$store.state.user.ready;
        },
        getInitials() {
            return this.$store.getters.userInitials;
        },
    },
}
</script>