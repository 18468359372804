
/**
 * Test a string to see if it is date format YYYY-MM-DD
 * @param {string} value Value to test
 * @param {string} [prompt=Format invalide (AAAA-MM-JJ)] Prompt to show if false
 * @returns {boolean|string} Returns string if format is valid, otherwise the prompt
 * @version 2
 */
export function DATE(value, prompt = 'Format invalide (AAAA-MM-JJ)') {
    const pattern = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/
    return pattern.test(value) || prompt;
}

/**
 * Test a string to see if it is non null
 * @param {string} value Value to test
 * @param {string} [prompt=Ce champ est requis] Prompt to show if false
 * @returns {boolean|string} Returns string if format is valid, otherwise the prompt
 * @version 1
 */
export function REQUIRED(value, prompt = 'Ce champ est requis') {
    return !!value || prompt;
}

/**
 * Test a string to see if it is an email address
 * @param {string} value Value to test
 * @param {string} [prompt=Format invalide] Prompt to show if false
 * @returns {boolean|string} Returns string if format is valid, otherwise the prompt
 * @version 2
 */
export function EMAIL(value, prompt = 'Format invalide') {
    //pattern source: https://emailregex.com/
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || prompt;
}

/**
 * Test a string to see if it is a swiss or french phone number
 * @param {string} value Value to test
 * @param {string} [prompt=Format invalide] Prompt to show if false
 * @returns {boolean|string} Returns string if format is valid, otherwise the prompt
 */
export function TELEPHONE(value, prompt = 'Format invalide') {
    const pattern = /^(41|33)\d{9}$/
    return pattern.test(value) || prompt;
}