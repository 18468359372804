import Vue from 'vue'
import Vuex from 'vuex'
// Firebase
import fbapp from '@/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        authReady: false,
        user: {
            loggedIn: false,
            ready: false,
            data: new window.UserProfile(),
        },
        error: {
            active: false,
            type: '',
            code: null,
            message: '',
        },
        snackbar: {
            active: false,
            text: '',
            color: 'info',
            icon: null,
        }
    },
    mutations: {
        authStateChange(state, authState) {
            state.authReady = true;
            state.user.loggedIn = authState;
        },
        setUserData(state, userData) {
            if (userData) {
                state.user.data = new window.UserProfile(userData);
                state.user.ready = true;
            } else {
                state.user.data = new window.UserProfile();
                state.user.ready = false;
            }
        },
        setError(state, error) {
            state.error.message = error.message || '';
            state.error.type = error.type || '';
            state.error.code = error.code || null;
            state.error.active = true;
        },
        setSnackbar(state, data) {
            state.snackbar.text = data.text || '';
            state.snackbar.color = data.color || 'info';
            state.snackbar.icon = data.icon || null;
            state.snackbar.active = true;
        },
    },
    actions: {
    },
    modules: {
    },
    getters: {
        /**
         * Get user's initials
         * @param {object} state Store state
         * @returns {string} User's initials
         * @deprecated
         */
        userInitials: state => {
            if (state.user.data.name) {
                const name = state.user.data.name;
                return name.first.charAt(0) + name.last.charAt(0);
            }
            return undefined;
        },
        /**
         * @deprecated
         */
        hasAnyClaim: (state) => (...claims) => {
            if (!state.user.ready || !state.user.data.claims)
                return false;

            for (let claim of claims) {
                if (state.user.data.claims[claim] === true)
                    return true;
            }
        },
        /**
         * Same as hasAnyClaim but with supers included
         * @param {...string} claims Claims to check for
         * @deprecated
         */
        canAccess: (state, getters) => (...claims) => {
            if (!state.user.ready)
                return false;
            
            if (getters.hasAnyClaim('superAdmin', 'superMod', ...claims))
                return true;
        },
    }
})

export default store;

// FIREBASE
const auth = getAuth(fbapp);
const fs = getFirestore(fbapp);
auth.languageCode = 'fr';
let dbUnsub;

onAuthStateChanged(auth, function(user) {
    store.commit('authStateChange', !!user);
    if (user) {
        dbUnsub = onSnapshot(doc(fs, 'users', user.uid), doc => {
            if (doc.exists) {
                let userData = doc.data();
                userData.uid = user.uid;
                user.getIdToken(true).then(() => { //Get claims
                    return user.getIdTokenResult()
                }).then(tokenResult => {
                    userData.claims = tokenResult.claims;
                    store.commit('setUserData', userData);
                });
            }
        }, err => {
            store.commit('setError', { code: err.code, type: 'firebase-firestore' });
        });
    }
    else {
        store.commit('setUserData', null);
        if(dbUnsub)
            dbUnsub();
    }
})